
import { defineComponent, watch } from "vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import apiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "delete-region",
  props: {
    data: {
      type: Object,
      required: true,
    },
    check: {
      type: Boolean,
      required: true,
    },
    page: {
      required: true,
    },
    perPage: {
      required: true,
    },
    column: {
      required: true,
    },
    orderType: {
      required: true,
    },
  },
  emit: ["modal-value"],
  setup: function (props, { emit }) {
    const router = useRouter();
    watch(
        () => props.check,
        () => {
          swalAlert(props.data);
        }
    );

    function swalAlert(data) {
      Swal.fire({
        title: `Are you sure you want to delete the region - ${data.name}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteRegion(data.id);
        }
      });
    }

    function deleteRegion(id) {
      apiService
          .delete(`/region/${id}?perPage=${props.perPage}&page=${props.page}&column=${props.column}&orderType=${props.orderType}`)
          .then((resp) => {
            let data = {
              status: "deleted",
              data: resp.data,
            };
            emit("modal-value", data);
            Swal.fire("Deleted!", "Your region been deleted.", "success");
          })
          .catch(function (error) {
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
          });
    }
  },
});
