
import { defineComponent, onMounted } from "vue";
import RegionTable from "@/components/widgets/tables/RegionTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "regions",
  components: {
    RegionTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Regions");
    });
  },
});
