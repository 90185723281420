
import { defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import apiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default defineComponent({
  name: "region-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
    QuillEditor,
  },
  props: {
    data: {
      type: Object,
    },
    change: {
      required: true,
    },
    id: {
      type: Number,
    },
    perPage: {
      required: true,
    },
    page: {
      required: true,
    },
    column: {
      required: true,
    },
    orderType: {
      required: true,
    },
  },
  emit: ["modal-value", "status-value"],

  setup: function (props, { emit }) {
    let submitButtonRef = ref<null | HTMLButtonElement>(null);
    const listModalRef = ref<null | HTMLElement>(null);
    let formRef = ref<null | HTMLElement>();
    let errorsRef = ref(null);
    let qEditor = ref<boolean>(false);
    let canSend = ref<boolean>(true);

    const router = useRouter();
    let listData = ref({
      name: "",
      welcome_message: "",
      perPage: props.perPage,
      page: 1,
      column: props.column,
      orderType: props.orderType,
    });
    watch(
      () => props.change,
      async () => {
        qEditor.value = false;
        canSend.value = true;
        if (props.id) {
       await   Object.assign(listData.value, props.data);
        } else {
          listData.value = {
            name: "",
            welcome_message: "",
            perPage: props.perPage,
            page: 1,
            column: props.column,
            orderType: props.orderType,
          };
          let button = await document.getElementById("kt_modal_list_cancel");
          await button?.click();
        }
        errorsRef.value = null;
        qEditor.value = true;

      }
    );
    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name")
    });

    const submit = async () => {
      if (!canSend.value) {
        return false;
      }
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButtonRef.value || count > 0) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      if (props.id) {
        await apiService
          .put("/region/" + props.id, listData.value)
          .then((resp) => {
            let data = {
              status: "updated",
              data: resp.data.data,
            };
            canSend.value = false;
            success(data, "updated");
          })
          .catch(function (error) {
            canSend.value = true;
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
            if (error.response && error.response.status == 422) {
              getError(error.response?.data.errors);
            }
          });
      } else {
        await apiService
          .post("/region", listData.value)
          .then((resp) => {
            let data = {
              status: "created",
              data: resp.data,
            };
            canSend.value = false;
            success(data, "created");
            hideLoading();
          })
          .catch(function (error) {
            canSend.value = true;
            hideLoading();
            if (error.response && error.response.status == 422) {
              getError(error.response?.data.errors);
            }
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
          });
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
      }
    };

    function hideLoading() {
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    }

    function getError(error) {
      errorsRef.value = error;
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    }

    function success(data, method) {
      emit("modal-value", data);
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
      if (method === "created") {
        Swal.fire({
          text: `The region has been successfully ${data.status}!`,
          icon: "success",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Add Region",
          cancelButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          hideModal(listModalRef.value);
          if (result.isConfirmed) {
            canSend.value = true;
            emit("new-modal", "new");
          }
        });
      } else if (method === "updated") {
        Swal.fire({
          text: `The region has been successfully ${data.status}!`,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          canSend.value = true;
          hideModal(listModalRef.value);
        });
      }
    }

    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };
    return {
      validationSchema,
      submit,
      submitButtonRef,
      formRef,
      listData,
      listModalRef,
      hideError,
      errorsRef,
      canSend,
      qEditor,
    };
  },
});
